<template>
  <div class="d-flex align-center justify-center fill-height">
    <ant-loading />
  </div>
</template>

<script>
import AntLoading from '@/components/AntLoading';
import { authenticateAzureUser } from '@/services/api/user.api';
import LocalStorageService from '@/services/local-storage';

export default {
  name: 'LoginAzureAD',
  components: { AntLoading },

  mounted() {
    const code = this.$route.query.code;
    authenticateAzureUser(code).then((response) => {
      let data = response;
      let accessToken = data.access_token || data.token || null;

      if (accessToken) {
        LocalStorageService.setToken(accessToken);
        LocalStorageService.setTokenExpiresIn(
          Date.now() + data.expires_in * 1000
        );
        this.$router.push({ name: 'Dashboard' });
      } else {
        // TODO throw error
      }
    });
  },
};
</script>

<style scoped></style>
